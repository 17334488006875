import { HttpClient, HttpContext } from '@angular/common/http';
import { DestroyRef, inject, Injectable, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ToastrService } from 'ngx-toastr';
import { catchError, map, of, tap } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { PATH_CREATE_ACCOUNT_CAPIVARA, PATH_LOGIN_CAPIVARA, PATH_REFRESH_TOKEN_CAPIVARA } from '../assets/routes';
import { IS_PUBLIC } from '../interceptors';
import { BaseService } from './base/base.service';
import { BaseResponse, CreateAccountRequest, LoginRequest, LoginResponse } from './dtos';
import { StoreService } from './store.service';
import { getRegisterErrorsMessages } from './utils/errors';

@Injectable({
  providedIn: 'root',
})
export class AuthTokenService extends BaseService {
  public isRefreshing = false;
  private readonly TOKEN_EXPIRY_THRESHOLD_MINUTES = 4;
  private readonly destroyRef = inject(DestroyRef);
  private readonly router = inject(Router);
  private readonly jwtHelper = inject(JwtHelperService);
  private readonly CONTEXT = new HttpContext().set(IS_PUBLIC, true);
  private readonly toastrService = inject(ToastrService);
  private refreshTokenTimeout: any;
  public readonly responseErrorInfo: { [key: string]: string } = {
    "PromoCodeNotFound": "Código de criação inválido",
    "UserAlreadyExists": "Usuário já existente",
    "Invalid user or password": "Usuário ou senha incorretos",
    "Invalid code": "Código inválido",
    "Invalid token": "Sessão expirada. Por favor, autentique-se novamente.",
    "Store already exists": "Armazenamento já existente"
  }

  constructor(
    http: HttpClient,
    private storeService: StoreService
  ) {
    super(http);
  }

  /**
   * Get user data.
   */
  get user(): WritableSignal<any | null> {
    const token = localStorage.getItem('token');

    return signal(token ? this.jwtHelper.decodeToken(token) : null);
  }

  /**
   * Validate if user is authenticated.
   * 
   * @returns {boolean} True if user authenticated.
   */
  isAuthenticated(): boolean {
    return !this.jwtHelper.isTokenExpired();
  }

  /**
   * Indicates if has token stored at localStorage.
   * 
   * @returns {boolean} True if has token.
   */
  hasToken(): boolean {
    const token = localStorage.getItem('token');

    return token !== null;
  }

  /**
   * Logout user.
   */
  logout(): void {
    localStorage.removeItem('token');
    localStorage.removeItem('refresh_token');
    this.stopRefreshTokenTimer();
    this.router.navigate(['']);
  }

  /**
   * Create account at plataform.
   * 
   * @param {CreateAccountRequest} body User informations.
   * 
   * @returns {Observable<BaseResponse<LoginResponse>>} Logged user.
   */
  createAccount(body: CreateAccountRequest): Observable<BaseResponse<LoginResponse>> {
    return super
      .post(PATH_CREATE_ACCOUNT_CAPIVARA, body, null, this.CONTEXT)
      .pipe(
        catchError(error => {
          if (error.status === 401) {
            this.toastrService.error(this.responseErrorInfo[error]);
            return of();
          }

          this.toastrService.error(error.message ?? 'Sistema indisponível no momento.');
          return of();
        }),
        tap((response: any) => {
          if (response.error === true) {
            const message = getRegisterErrorsMessages(response.message);

            this.toastrService.error(message);
          }

          this.storeService.selectedStores = [];
          this.storeTokens(response.body);
          this.scheduleTokenRefresh(response.body.access_token);
          this.router.navigate(['dashboard']);
        }),
      );
  }

  /**
   * Authenticate user.
   * 
   * @param {LoginRequest} body User data to login.
   * 
   * @returns {Observable<BaseResponse<LoginResponse>>} Logged user.
   */
  login(body: LoginRequest): Observable<BaseResponse<LoginResponse>> {
    return super
      .post(PATH_LOGIN_CAPIVARA, body, null, this.CONTEXT)
      .pipe(
        map((response) => {
          let loginSuccessData: any;

          if (!response.error) {
            loginSuccessData = response.body;
            this.storeService.selectedStores = [];
            this.storeTokens(loginSuccessData);
            this.scheduleTokenRefresh(loginSuccessData.access_token);
            this.router.navigate(['dashboard']);
          }

          return loginSuccessData;
        }),
      );
  }

  /**
   * Refresh token.
   * 
   * @returns {Observable<BaseResponse<LoginResponse>>} Login response.
   */
  refreshToken(): Observable<BaseResponse<LoginResponse> | null> {
    const refresh_token = localStorage.getItem('refresh_token');
    this.isRefreshing = true;

    if (refresh_token) {
      return super.post(PATH_REFRESH_TOKEN_CAPIVARA, { token: refresh_token }, null, this.CONTEXT)
        .pipe(
          map((response) => {
            let loginSuccessData: any;

            if (!response.error) {
              loginSuccessData = response.body;
              this.isRefreshing = false;

              this.storeTokens(loginSuccessData);
              this.scheduleTokenRefresh(loginSuccessData.access_token);

              this.isRefreshing = false;
            }

            return loginSuccessData;
          }),
        );
    }

    return of();
  }

  /**
   * Schedule to refresh token.
   * 
   * @param {string} token Token.
   */
  scheduleTokenRefresh(token: string): void {
    const expirationTime = this.jwtHelper.getTokenExpirationDate(token)?.getTime();
    let timeout = expirationTime ? expirationTime - Date.now() - (this.TOKEN_EXPIRY_THRESHOLD_MINUTES * 60 * 1000) : undefined;

    if (!timeout || timeout <= 0) {
      timeout = 60 * 1000;
    }

    this.refreshTokenTimeout = setTimeout(() => {
      this.refreshToken()
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe();
    }, timeout);
  }

  /**
   * Store JWT tokens.
   * 
   * @param data 
   */
  storeTokens(data: LoginResponse): void {
    localStorage.setItem('token', data.access_token);
    localStorage.setItem('refresh_token', data.refresh_token);
  }

  /**
   * Stop automatic refresh token.
   */
  stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }
}
