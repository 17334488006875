export const PATH_LOGIN_CAPIVARA = 'v1/users/token';

export const PATH_REFRESH_TOKEN_CAPIVARA = 'v1/users/refresh';

export const PATH_CREATE_ACCOUNT_CAPIVARA = 'v1/users';

export const PATH_INIT_AUTHORIZATION_ML = 'v1/authorization/mercado-livre';

export const PATH_APPROVE_AUTHORIZATION_ML = 'v1/authorization/mercado-livre/approve';

export const PATH_FILTER_ORDERS = 'v1/orders';

export const PATH_INCOMES_TODAY = 'v1/incomes';

export const PATH_INCOMES_CHART = 'v1/incomes/chart';

export const PATH_ORDERS_STATES = 'v1/orders/summary/states';

export const PATH_STORES = 'v1/stores';

export const PATH_CLAIMS = 'v1/claims/summary';

export const PATH_QUESTIONS = 'v1/questions/summary';

export const PATH_CURRENT_STATUS = 'v1/orders/status';

export const PATH_ORDERS_SUMMARY = 'v1/orders/summary';

export const PATH_ORDER_ITEMS = 'v1/orders';

export const PATH_INCOMES_STATES = 'v1/incomes/states';

export const PATH_REPORTS_OVERVIEW = 'v1/reports/overview';

export const PATH_LIST_ORDER_ITEMS = 'v1/orders/items';

export const PATH_LIST_ORDERS_SKU = 'v1/orders/summary/sku';

export const PATH_STATUS_DOMAIN = 'v1/orders/domain/status';

export const PATH_EXPORT_ORDERS = 'v1/orders/export';

export const PATH_EXPORT_ORDERS_STATES = 'v1/orders/summary/states/export'

export const PATH_BOT_KEY = 'v1/bot-key';