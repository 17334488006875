import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { APIResponseDto, StoreDto } from '../app/shared/models/dtos';
import { PATH_BOT_KEY, PATH_STORES } from '../assets/routes';
import { BaseService } from './base/base.service';
import { BotKeyResponseDto } from '../app/components/modal-whats-app-access/dto/bot-key.response.dto';

@Injectable({
    providedIn: 'root',
})
export class AccountService extends BaseService {

    /**
     * Selected stores subject.
     */
    private selectedStoresSubject = new Subject<StoreDto[]>();

    /**
     * Selected stores observable.
     */
    public selectedStores$: Observable<StoreDto[]> = this.selectedStoresSubject.asObservable();

    constructor(
        http: HttpClient
    ) {
        super(http);
    }

    public emitStoreSelectionChange(selectedStores: StoreDto[]): void {
        this.selectedStoresSubject.next(selectedStores);
    }

    public getBotKey(): Observable<APIResponseDto<BotKeyResponseDto>> {
        return super.get(PATH_BOT_KEY);
    }

    public getAccounts(): Observable<any> {
        const accessToken = localStorage.getItem('access_token');

        return super.get(
            PATH_STORES,
            {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            }
        );
    }

    public updateNameAccount(name: string, storeId: string): Observable<any> {
        const accessToken = localStorage.getItem('access_token');

        return super.put(
            `${PATH_STORES}/${storeId}`,
            {
                name
            },
            {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            }
        );
    }

    public RemoveAccount(storeId: string): Observable<any> {
        return super.delete(`${PATH_STORES}/${storeId}`);
    }

    public SyncAccount(storeId: string): Observable<any> {
        const accessToken = localStorage.getItem('access_token');

        return super.post(
            `${PATH_STORES}/${storeId}/sync`,
            {},
            {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            }
        );
    }
}