import { HttpContextToken, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from "@angular/core";
import { catchError, throwError } from 'rxjs';
import { AuthTokenService } from '../services/auth-token.service';
import { ToastrService } from 'ngx-toastr';

export const errorInterceptor: HttpInterceptorFn = (req, next) => {
    const authSvc = inject(AuthTokenService);
    const toastrService = inject(ToastrService);

    return next(req).pipe(catchError(err => {
        if ([401, 403].includes(err.status)) {
            toastrService.error(authSvc.responseErrorInfo[err?.error?.message] ?? 'Sistema indisponível no momento.');
            
            authSvc.logout();
        }

        return throwError(() => err);
    }));
};
