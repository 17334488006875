import { Injectable } from '@angular/core';
import { BaseService } from './base/base.service';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, catchError, throwError } from 'rxjs';
import { PATH_EXPORT_ORDERS, PATH_FILTER_ORDERS, PATH_STATUS_DOMAIN } from '../assets/routes';
import { environment } from '../environments/environment';
import { StoreDto } from '../app/shared/models/dtos';

@Injectable({
  providedIn: 'root'
})
export class StoreService {
  private readonly _selectedStores = new BehaviorSubject<StoreDto[]>([]);
  readonly selectedStores$ = this._selectedStores.asObservable();

  get selectedStores(): StoreDto[] {
    return this._selectedStores.getValue();
  }

  set selectedStores(stores: StoreDto[]) {
    this._selectedStores.next(stores);
  }
}
