import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { IS_PUBLIC } from '../../interceptors';

@Injectable({
  providedIn: 'root',
})
export class BaseService {
  private readonly baseUrl = environment.API_URL;
  private readonly defaultContext = new HttpContext().set(IS_PUBLIC, false);

  constructor(
    private readonly http: HttpClient
  ) { }

  get(url: string, headers?: any, params?: any, context: HttpContext = this.defaultContext): Observable<any> {
    return this.http.get(`${this.baseUrl}/${url}`, { headers, params, context, }).pipe(
      catchError((error) => {
        return of({ error: true, info: error, message: error?.error?.message });
      })
    );
  }
  post(url: string, data: any, headers?: any, context: HttpContext = this.defaultContext): Observable<any> {
    return this.http.post(`${this.baseUrl}/${url}`, data, { headers, context, }).pipe(
      catchError((error) => {
        return of({ error: true, message: error?.error?.message, info: error });
      })
    );
  }
  put(url: string, data: any, headers?: any, context: HttpContext = this.defaultContext): Observable<any> {
    return this.http.put(`${this.baseUrl}/${url}`, data, { headers, context, }).pipe(
      catchError((error) => {
        return of({ error: true, message: error?.error?.message, info: error });
      })
    );
  }
  delete(url: string, headers?: any, context: HttpContext = this.defaultContext): Observable<any> {
    return this.http.delete<void>(`${this.baseUrl}/${url}`, {
      headers,
      context,
    }).pipe(
      catchError((error) => {
        return of({ error: true, message: error?.error?.message, info: error });
      })
    );
  }
  patch(url: string, data: any, context: HttpContext = this.defaultContext): Observable<any> {
    return this.http.patch(`${this.baseUrl}/${url}`, data).pipe(
      catchError((error) => {
        return of({ error: true, message: error?.error?.message, info: error });
      })
    );
  }
}
